import React from 'react'
import { Link } from 'gatsby'

import {
    announcement,
    announcement_main,
    announcement_title,
    announcement_date
} from "./Announcement.module.css"

const Announcement = ( props ) => {
    return (
        <Link to={props.linkTo} className={announcement}>
            <div className={announcement_main}>
                <h2 className={announcement_title}>{props.title}</h2>
                <span className={announcement_date}>{props.date}</span>
            </div>
        </Link>
    )
}

export default Announcement
