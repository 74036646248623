import React from "react"

import Announcement from "../Announcement"
import { announcementsGrid } from "./AnnouncementsGrid.module.css"

const AnnouncementsGrid = ({ posts }) => {
  return (
    <div className={announcementsGrid}>
      {posts.map(post => {
        return (
          <Announcement
            key={post.title}
            title={post.title}
            date={post.date}
            location={post.location}
            linkTo={"" + post.slug}
          />
        )
      })}
    </div>
  )
}

export default AnnouncementsGrid
