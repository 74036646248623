import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import AnnouncementsGrid from "../components/AnnouncementsGrid"


const Announcements = ({ data, location }) => {
  const posts = data.allContentfulAnnouncement.nodes

    if (posts.length === 0) {
        return (
            <p>
                No blog posts found. Add markdown posts to "content/blog" (or the
                directory you specified for the "gatsby-source-filesystem" plugin in
                gatsby-config.js).
            </p>
        )
    }

  return (
    <Layout location={location}>
      <Seo title="Announcements" />
      <div className="section-wrapper page-main">
        <h1 className="page-main-title">Announcements</h1>
        <AnnouncementsGrid posts={posts}/>
      </div>
    </Layout>
  )
}

export default Announcements

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulAnnouncement(
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        title
        slug
        date(formatString: "MMMM Do, YYYY")
      }
    }
  }
`